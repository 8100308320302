<template>
  <div id="app">
    <GymCompanion />
  </div>
</template>

<script>
import GymCompanion from './components/GymCompanion.vue'

export default {
  name: 'App',
  components: {
    GymCompanion
  }
}
</script>
