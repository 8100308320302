import Vue from 'vue'
import Gapi from 'vue-googleapis'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(Gapi, {
  apiKey: 'AIzaSyBcbtWkXgrn34pCiStJ_5nkS-F7mw6VWkE',
  clientId: '6190824320-d9tccmojls0poldugev7j4ons5fa3haf.apps.googleusercontent.com',
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
  scope: "profile email https://www.googleapis.com/auth/calendar",
})

new Vue({
  render: h => h(App),
}).$mount('#app')
