<template>
  <div id="gc">
    <h1>GymCompanion</h1>
    <button
      v-if="!isSignedIn && isInit"
      :disabled="!isInit || isSignedIn"
      @click="signIn"
    >Se connecter</button>
    <form v-if="this.eventsLoaded" @submit="createWorkout" action="#">
      <table>
        <thead>
          <th>Groupe</th>
          <th>#31j</th>
          <th>age</th>
        </thead>
        <tbody>
          <tr
            v-for="t in types"
            @click.prevent="toggle(t)"
            :key="t"
            :class="{ selected: selectedTypes.includes(t) }"
          >
            <td>
              {{ t }}
            </td>
            <td>
              {{ latest[t].nb }}
            </td>
            <td :class="{ warning: isMaximum(latest[t].nbDays) }">
              {{ latest[t].nbDays }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="selectedTypes.length > 0" class="selection">
        {{ selectedTypes }}
      </div>
      <input v-if="selectedTypes.length > 0" type="submit" value="Sauver" />
    </form>
    <div v-if="(eventsLoaded == false && isSignedIn) || (!isSignedIn && !isInit)" class="loader"></div>
    <button
        v-if="isSignedIn"
        @click.prevent="signout"
        style="right: 5px; bottom: 5px; position: absolute"
      >
        Déconnecter {{ user.Pe }} 
    </button>
  </div>
</template>

<script>
export default {
  name: "GC",
  data() {
    return {
    isInit: false,
    eventsLoaded: false,
    user: {},
    isSignedIn: false,
    calendarId: -1,
    events: [],
    types: ["abdos", "bras", "cardio", "dos", "épaules", "jambes", "pectoraux", "mdt"],
    selectedTypes: [],
    }
  },
  created ()  {
    const checkGALoad = setInterval(() => {
      console.log("check init")
      this.isInit = this.$google.isInit
      if (this.isInit) {
        this.isSignedIn = this.$google.api.auth2.getAuthInstance().isSignedIn.get()
        if(this.isSignedIn) {
          this.user = this.$google.api.auth2.getAuthInstance().currentUser.get().getBasicProfile()
          clearInterval(checkGALoad)
          this.getCalendars()
        }
      }
    }, 1000)
  },
  computed: {
    latest() {
      let ret = {};
      this.types.forEach((t) => {
        ret[t] = {
          start: 0,
          nbDays: 99,
          nb: 0,
        };
      });
      this.events.forEach((e) => {
        let types = JSON.parse(e.summary);
        types.forEach((t) => {
          ret[t] = {
            start: e.start.dateTime,
            nbDays: this.countDays(e.start.dateTime),
            nb: ret[t].nb + 1,
          };
        });
      });
      return ret;
    },
  },
  methods: {
    toggle(t){
      const index = this.selectedTypes.indexOf(t)
      if (index !== -1) {
        this.selectedTypes.splice(index, 1);
      } else {
        this.selectedTypes.push(t)
      }
    },
    countDays(val) {
      let d = new Date(val);
      let t = new Date();
      let diff = t.getTime() - d.getTime();
      return Math.round(diff / (1000 * 3600 * 24));
    },
    createWorkout: function(e) {
      e.preventDefault();
      let start = new Date();
      let startString = start.toISOString();
      start.setHours(start.getHours() - 1);
      let endString = start.toISOString();
      this.$google.api.client.calendar.events
        .insert({
          calendarId: this.calendarId,
          resource: {
            summary: JSON.stringify(this.selectedTypes),
            start: {
              dateTime: endString,
            },
            end: {
              dateTime: startString,
            },
          },
        })
        .then(() => {
          this.listEvents();
        });
      this.events.splice(0);
      this.selectedTypes.splice(0);
    },
    listEvents() {
      console.log("listing...");
      let timeMin = new Date();
      timeMin.setMonth(timeMin.getMonth() - 1);
      this.$google.api.client.calendar.events
        .list({
          calendarId: this.calendarId,
          timeMin: timeMin.toISOString(),
          timeMax: new Date().toISOString(),
          showDeleted: false,
          singleEvents: true,
          maxResults: 100,
          orderBy: "startTime",
        })
        .then((response) => {
          this.eventsLoaded = true
          response.result.items.forEach((e) =>
            this.events.push({
              summary: e.summary,
              start: e.start,
              end: e.end,
            })
          );
        });
    },
    isMaximum(nb) {
      let ret = true;
      this.types.forEach((t) => {
        if (this.latest[t].nbDays > nb) {
          ret = false;
        }
      });
      return ret;
    },
    getCalendars() {
      this.$google.api.client.calendar.calendarList
        .list({ minAccessRole: "owner" })
        .then((response) => {
          let gcCal = response.result.items.filter(
            (cal) => cal.summary === "GymCompanion"
          );
          if (gcCal.length === 0) {
            console.log("creation du calendrier");
            this.$google.api.client.calendar.calendars
              .insert({ summary: "GymCompanion" })
              .then((response) => {
                this.calendarId = response.result.id;
                this.listEvents();
              });
          } else {
            this.calendarId = gcCal[0].id;
            this.listEvents();
          }
        });
    },
    signout() {      
      this.$google.api.auth2.getAuthInstance().signOut();
      window.location.reload()
    },
    isSignedId () {
      return  this.$google.api.auth2.getAuthInstance().isSignedIn.get()
    },
    async signIn () {
      await this.$google.api.auth2.getAuthInstance().signIn()
    },
  },
};
</script>
